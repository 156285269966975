$flex-gap: 5px;

.adv_cal_cap {
  .adv-cal-cap-container {
    background-size: cover;
    height: 100%;
    .adv-cal-cap-wrapper {
      .cards {
        gap: $flex-gap;
        margin: auto;
        padding: 15px;
        @media #{$cr19-medium-up} {
          padding: 0;
        }
        .card {
          background-repeat: no-repeat;
          flex: 1 1 calc(33% - #{$flex-gap});
          gap: $flex-gap;
          height: auto !important;
          margin: 0 !important;
          padding-bottom: calc(37%);
          width: auto !important;
          @media #{$cr19-medium-up} {
            flex: 0 0 calc(25% - #{$flex-gap});
            padding-bottom: calc(26%);
          }
          &.active {
            border: 0;
            box-shadow: none;
            &:hover {
              box-shadow: none;
            }
          }
          &.future {
            &:hover {
              box-shadow: none;
            }
          }
        }
      }
    }
    .adv-cal-cap-popup {
      .offer-details {
        height: 100%;
      }
      .offer-details__controls {
        width: 100% !important;
        @media #{$cr19-medium-up} {
          padding-#{$ldirection}: 12px;
          width: 50% !important;
          position: absolute;
          bottom: 0;
          #{$rdirection}: 0;
        }
        .button {
          @include elc-button;
          @include elc-button--dark;
          display: block;
          margin-top: 11px;
          padding: 0;
          text-transform: none;
          height: 3em;
          line-height: 3;
          @media #{$cr19-medium-up} {
            display: block;
            margin-top: 15px;
            width: 50%;
            min-width: auto;
          }
        }
        .button:nth-child(even) {
          @include elc-button;
          @include elc-button--light;
          display: block;
          height: 3em;
          line-height: 3;
          @media #{$cr19-medium-up} {
            display: inline-block;
            width: 50%;
            min-width: auto;
          }
        }
      }
      .popup-content-wrapper {
        background-image: url('/media/export/cms/holiday_advent_calendar/2022/mobile/F22_Hol_AdvCal_Offer-Window-BG_m.jpg');
        background-size: 100% 100%;
        border-radius: 0;
        height: auto;
        max-width: 100%;
        padding: 16px 14px;
        @media #{$cr19-medium-up} {
          background-image: url('/media/export/cms/holiday_advent_calendar/2022/desktop/F22_Hol_AdvCal_Offer-Window-BG_dt.jpg');
          padding: 50px;
        }
      }
      .offer-details__panel {
        width: 100% !important;
        height: 100% !important;
      }
      .offer-details__content {
        width: 50%;
        padding-#{$ldirection}: 8px;
        @media #{$cr19-medium-up} {
          padding-#{$ldirection}: 12px;
          height: auto;
        }
        .subtitle {
          font-weight: bold;
        }
        .title {
          font-size: 26px;
          margin-top: 5px;
        }
        .description {
          margin-top: 5px;
        }
      }
      .offer-details__img {
        width: 50%;
        text-align: $rdirection;
        padding-#{$rdirection}: 8px;
        @media #{$cr19-medium-up} {
          height: 100%;
          padding-#{$rdirection}: 12px;
        }
        img {
          max-height: 100%;
          width: 100%;
          @media #{$cr19-medium-up} {
            max-width: 100%;
            width: auto;
          }
        }
      }
      .popup-close {
        background: none;
        transform: translate(50%, -50%);
        @media #{$cr19-medium-up} {
          transform: translate(30px, -30px);
        }
        &::after,
        &::before {
          background: $color-black;
        }
      }
    }
  }
}
